import { Divider, Stack, Box } from '@chakra-ui/react'
import { getCartSummaryAmounts } from 'composable/helpers/utils/cart-utils'
import { useFormat } from 'helpers/hooks/useFormat'
// helpers/currencyHelpers is for CT cart
// helpers_composable/currencyHelpers is for legacy cart
import { CurrencyHelpersWithoutBorderConversion } from 'helpers_composable/currencyHelpersWithoutBorderConversion'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import { SummaryLineItem } from '../../summary-line-item'

export const CartDrawerSummary = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { formattedCart: cart } = useAtgLegacyCart()
  const { subtotal, discounts, total, shipping, isShippingMethodSelected } = getCartSummaryAmounts(cart)
  const { userSessionData } = useAtgUser()

  return (
    <Box>
      <Stack spacing={1}>
        <SummaryLineItem
          label={formatMessage({ id: 'cart.summary.subtotal' })}
          value={CurrencyHelpersWithoutBorderConversion.formatForCurrency(subtotal, userSessionData.dxlCurrency)}
          textStyle="body-50"
        />

        {isShippingMethodSelected ? (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
            value={CurrencyHelpersWithoutBorderConversion.formatForCurrency(shipping, userSessionData.dxlCurrency)}
            textStyle="body-50"
          />
        ) : (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
            value={formatMessage({ id: 'cart.summary.calculatedAtCheckout' })}
            textStyle="body-50"
          />
        )}

        <SummaryLineItem
          label={formatMessage({ id: 'cart.summary.tax' })}
          value={formatMessage({
            id: 'cart.summary.tax.calculatedAtPOS',
          })}
          textStyle="body-50"
        />

        {!!discounts && (
          <SummaryLineItem
            isDiscount
            label={formatMessage({ id: 'cart.summary.discount' })}
            value={CurrencyHelpersWithoutBorderConversion.formatForCurrency(discounts, userSessionData.dxlCurrency)}
            textStyle="body-50"
          />
        )}
      </Stack>
      <Divider mt="2" mb="3" />

      <SummaryLineItem
        label={formatMessage({ id: 'cart.summary.estimatedTotal' })}
        value={
          CurrencyHelpersWithoutBorderConversion.formatForCurrency(total, userSessionData.dxlCurrency) ??
          CurrencyHelpersWithoutBorderConversion.formatForCurrency(0, userSessionData.dxlCurrency)
        }
        textStyle="heading-desktop-100"
      />
    </Box>
  )
}
