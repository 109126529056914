import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { Button, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { APP_VERSION, IS_LOCAL } from 'composable/components/general/constants'
import { getCartSummaryAmounts } from 'composable/helpers/utils/cart-utils'
import { useFormat } from 'helpers/hooks/useFormat'
// helpers/currencyHelpers is for CT cart
// helpers_composable/currencyHelpers is for legacy cart
import { CurrencyHelpersWithoutBorderConversion } from 'helpers_composable/currencyHelpersWithoutBorderConversion'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import { useComposable } from '../../composable-provider'
// import { CartPromotion, CartPromotionAccordion } from '../cart-promotion'

interface CartDrawerFooterItemProps {
  label: string
  value?: string
  children?: ReactNode
}

export const CartDrawerFooter = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const { cartDrawer } = useComposable()
  const { formattedCart: cart } = useAtgLegacyCart()
  const { total } = getCartSummaryAmounts(cart)
  const { userSessionData } = useAtgUser()

  const handleProceedToCart = async () => {
    if (APP_VERSION === 'R2' && !IS_LOCAL) {
      return window.location.assign('/cart/cart')
    }
    router.push('/cart')
  }

  return (
    <Stack spacing="3" width="full">
      <Stack spacing="4">
        {/* <CartPromotionAccordion
          isOpen={Boolean(cart?.discountCodes?.[0]?.code)}
          hideBorderTop
          showDiscount={
            <CartDrawerFooterItem
              label={formatMessage({
                id: 'cart.summary.discount',
              })}
            />
          }
        >
          <CartPromotion />
        </CartPromotionAccordion> */}
      </Stack>
      <Flex justify="space-between" align="center" wrap={'wrap'}>
        <VStack tabIndex={0} align="left" spacing="0">
          <Text textStyle={{ base: 'eyebrow-50', md: 'eyebrow-75' }} color={'text-muted'}>
            {formatMessage({ id: 'cart.summary.estimatedTotalShort' })}
          </Text>
          <Text textStyle={{ base: 'heading-desktop-75', md: 'heading-desktop-200' }}>
            {cart && CurrencyHelpersWithoutBorderConversion.formatForCurrency(total, userSessionData.dxlCurrency)}
          </Text>
        </VStack>
        <HStack flexGrow={9999} justifyContent={'flex-end'}>
          <Button
            onClick={async () => {
              handleProceedToCart()
              cartDrawer.onClose()
            }}
            variant={'solid'}
            textStyle={{ base: 'ui-button-75', md: 'ui-button-200' }}
            width={{ base: '89px', md: '132px' }}
            height={{ base: 8, md: 12 }}
          >
            {formatMessage({ id: 'action.viewBag' })}
          </Button>
        </HStack>
      </Flex>
    </Stack>
  )
}
