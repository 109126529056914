import { ReactNode } from 'react'
import { Text, HStack, StackProps } from '@chakra-ui/react'

type SummaryLineItemProps = StackProps & {
  label: string
  value?: string
  textStyle?: string
  children?: ReactNode
  isDiscount?: boolean
}

export const SummaryLineItem = ({ label, value, textStyle, children, isDiscount, ...props }: SummaryLineItemProps) => {
  return (
    <HStack tabIndex={0} justify="space-between" mt={1} {...props}>
      <Text textStyle={textStyle}>{label}</Text>
      {value ? (
        <Text textStyle={textStyle} color={isDiscount ? 'danger.600' : undefined} textAlign="right">
          {isDiscount && '-'}
          {value}
        </Text>
      ) : (
        children
      )}
    </HStack>
  )
}
